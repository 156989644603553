//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { axiosErrorMessageExtractor } from '@/utils/error'
import {
  TITLE_FOR_COMPLIANCE,
  TITLE_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE,
  TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL,
  DESCRIPTION_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE,
  DESCRIPTION_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL,
  TITLE_FOR_COMPLIANCE_ASSESSMENT,
  DESCRIPTION_FOR_COMPLIANCE_ASSESSMENT,
  TITLE_FOR_COMPLIANCE_PROPOSAL_DISCLOSURE,
  DESCRIPTION_FOR_COMPLIANCE_CLOSURE_PROPOSAL,
  LABEL_FOR_PRIMARY_APPLICANT,
  LABEL_FOR_SECONDARY_APPLICANT,
  LABEL_FOR_LAST_SENT_AT,
  LABEL_FOR_LAST_SIGNED_AT,
  LABEL_FOR_LAST_GENERATED_AT,
  LABEL_FOR_LAST_VIEWED_AT,
  LABEL_FOR_DATE,
  LABEL_FOR_MOBILE,
  LABEL_FOR_EMAIL,
  LABEL_FOR_IP,
  TITLE_FOR_INFO_ADDRESS_DETAILS,
  TITLE_FOR_INFO_LOAN_DETAILS,
  LABEL_FOR_PRIVACY_GUIDE,
  LABEL_FOR_PRIVACY_FORM,
  LABEL_FOR_CREDIT_QUOTE,
  LABEL_FOR_CREDIT_GUIDE,
  LABEL_FOR_QUOTE,
  LABEL_FOR_DOCUSIGN_CERT,
} from '~/constants'
import {
  LABEL_DOCUMENT_CONSUMER_CAR_LOAN,
  LABEL_FOR_VIEW,
  LABEL_PRELIMINARY_ASSESSMENT,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE,
  LIABILITY_TYPE_PERSONAL_LOAN,
  MAINTAB_DOCS_KEY,
  FINANCE_APPLICATION_TAB_DOCUMENTS_KEY,
  MAINTAB_COMPLIANCE_INSURANCE_KEY,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { getSignedURL } from '~/utils/url'
export default {
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  data() {
    return {
      TITLE_FOR_COMPLIANCE,
      TITLE_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE,
      TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL,
      DESCRIPTION_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE,
      DESCRIPTION_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL,
      TITLE_FOR_COMPLIANCE_ASSESSMENT,
      DESCRIPTION_FOR_COMPLIANCE_ASSESSMENT,
      TITLE_FOR_COMPLIANCE_PROPOSAL_DISCLOSURE,
      DESCRIPTION_FOR_COMPLIANCE_CLOSURE_PROPOSAL,
      LABEL_FOR_PRIMARY_APPLICANT,
      LABEL_PRELIMINARY_ASSESSMENT,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE,
      MAINTAB_DOCS_KEY,
      LABEL_FOR_SECONDARY_APPLICANT,
      LABEL_FOR_LAST_SENT_AT,
      LABEL_FOR_LAST_SIGNED_AT,
      LABEL_FOR_LAST_GENERATED_AT,
      LABEL_FOR_LAST_VIEWED_AT,
      LABEL_FOR_DATE,
      LABEL_FOR_MOBILE,
      LABEL_FOR_EMAIL,
      LABEL_FOR_IP,
      TITLE_FOR_INFO_ADDRESS_DETAILS,
      TITLE_FOR_INFO_LOAN_DETAILS,
      LABEL_FOR_PRIVACY_GUIDE,
      LABEL_FOR_PRIVACY_FORM,
      LABEL_FOR_CREDIT_QUOTE,
      LABEL_FOR_QUOTE,
      LABEL_FOR_CREDIT_GUIDE,
      LABEL_FOR_DOCUSIGN_CERT,
      LABEL_DOCUMENT_CONSUMER_CAR_LOAN,
      LIABILITY_TYPE_PERSONAL_LOAN,
      LABEL_FOR_VIEW,
      format,
      complianceData: {
        ...this.primaryData,
        ...this.secondaryData,
        ...this.sharedData,
      },
      counterForPrimary: 0,
      counterForSecondary: 0,
      getIsDialogLoading: false,
      proposalButtonLoading: false,
    }
  },
  computed: {
    ...mapGetters('applications', ['getPrimaryApplicant']),
    /* eslint-disable */
    complianceForm() {
      this.complianceData = {
        secondaryData: JSON.parse(JSON.stringify(this.secondaryData)),
        primaryData: JSON.parse(JSON.stringify(this.primaryData)),
        sharedData: JSON.parse(JSON.stringify(this.sharedData)),
      }
      return { ...this.primaryData, ...this.secondaryData, ...this.sharedData }
    },
    doesSecondaryApplicantExist() {
      return (
        this.complianceData.secondaryData &&
        this.complianceData.secondaryData.length
      )
    },
    hasPrivacyForm() {
      return this.hasDocumentsUrlFound(LABEL_FOR_PRIVACY_FORM)
    },
    hasCreditGuide() {
      return this.hasDocumentsUrlFound(LABEL_FOR_CREDIT_GUIDE)
    },
    hasQuote() {
      return this.hasDocumentsUrlFound(LABEL_FOR_CREDIT_QUOTE)
    },
    hasDocusignCert() {
      return this.hasDocumentsUrlFound(LABEL_FOR_DOCUSIGN_CERT)
    },
    /* eslint-enable */
    hasLoanTypeVisible() {
      const { loanType } = this.complianceData?.sharedData
      return (
        [
          LABEL_DOCUMENT_CONSUMER_CAR_LOAN,
          LIABILITY_TYPE_PERSONAL_LOAN,
        ].includes(loanType) || !loanType
      )
    },
    getPreliminaryUpdatedAt() {
      const { sharedDocuments } = this.complianceData?.sharedData
      let pdfData = null
      if (sharedDocuments) {
        sharedDocuments.forEach((i) => {
          if (
            i.purpose.toLowerCase() ===
            LABEL_PRELIMINARY_ASSESSMENT.toLowerCase()
          )
            pdfData = i.updatedAt
        })
      }
      return pdfData || ''
    },
    getCpdUpdatedAt() {
      const { sharedDocuments } = this.complianceData?.sharedData
      let pdfData = null
      if (sharedDocuments) {
        sharedDocuments.forEach((i) => {
          if (
            i.purpose.toLowerCase() ===
            LABEL_CREDIT_PROPOSAL_DISCLOSURE.toLowerCase()
          )
            pdfData = i.updatedAt
        })
      }
      return pdfData || ''
    },
    isViewButtonDisabled() {
      return (
        !this.hasPrivacyForm ||
        !this.complianceData?.primaryData?.eSignAt ||
        (this.complianceData?.secondaryData?.length &&
          this.complianceData?.secondaryData.some((x) => !x.eSignAt))
      )
    },
  },
  methods: {
    hasDocumentsUrlFound(purpose) {
      const { sharedDocuments } = this.complianceData.sharedData
      let checkDocUrl = null
      if (sharedDocuments) {
        sharedDocuments.forEach((doc) => {
          if (doc.purpose.toLowerCase() === purpose.toLowerCase())
            checkDocUrl = getSignedURL(doc.url, this.$auth)
        })
      }
      return checkDocUrl ? true : false
    },
    handleDocumentsUrl(purpose) {
      const { sharedDocuments } = this.complianceData?.sharedData
      if (sharedDocuments) {
        let redirectUrl = null
        sharedDocuments.forEach((doc) => {
          if (doc.purpose.toLowerCase() === purpose.toLowerCase())
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            redirectUrl = getSignedURL(doc.url, this.$auth)
        })
        window.open(redirectUrl, '_blank')
      }
    },
    async sendESignEmailRequest(applicantId) {
      this.getIsDialogLoading = true
      try {
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        const { id, applicationId } = this.$route.params
        await this.$store.dispatch('applications/eSignRequestEmail', {
          id,
          applicationId,
          applicantId,
        })

        await this.$store.dispatch('applications/fetchApplications', {
          id,
          applicationId,
        })
        this.getIsDialogLoading = false
      } catch (err) {
        this.getIsDialogLoading = false
        console.log(err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    async sendProposal() {
      this.proposalButtonLoading = true
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch('applications/sendProposal', {
        id,
        applicationId,
        updateStatus: false,
      })
      this.proposalButtonLoading = false
    },
    async submit() {
      this.$nuxt.$emit(
        'change-compliance-tab',
        MAINTAB_COMPLIANCE_INSURANCE_KEY
      )
    },
    async backToPrevious() {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_TAB_DOCUMENTS_KEY,
        })
      }
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: MAINTAB_DOCS_KEY,
        },
      })
      this.$nuxt.$emit('updateComponent', MAINTAB_DOCS_KEY)
    },
  },
}
